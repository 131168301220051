import { fetchWrapperPortal } from "./fetch";

export const update = async (pollId, update) => {
  const response = await fetchWrapperPortal({
    url: `polls/${pollId}`,
    method: `PUT`,
    addBearer: true,
    body: JSON.stringify(update)
  });
  return response;
};
export const deletePoll = async pollId =>
  await fetchWrapperPortal({
    url: `polls/${pollId}`,
    method: `DELETE`,
    addBearer: true
  });

export const exportPolls = async (streamId) => {
  const response = await fetchWrapperPortal({
    url: `polls/export/${streamId}`,
    method: 'GET',
    addBearer: true
  });
  return response;
};