import { fetchWrapperPlatform } from "./fetch";
import { setGlobal, resetGlobal } from "reactn";

export const signup = async user =>
  await fetchWrapperPlatform({
    url: `users/`,
    method: `POST`,
    body: JSON.stringify(user)
  });

export const update = async (userId, payload) => {
  let res = await fetchWrapperPlatform({
    url: `users/` + userId,
    method: "PUT",
    addBearer: true,
    body: JSON.stringify(payload)
  });
  if (res.success) {
    persistUser(res.user);
    return res.user;
  } else {
    return null;
  }
};

export const uploadImage = async (file, type) => {
  console.log("upload", file.type, type);
  const result = await fetchWrapperPlatform({
    url: `users/image_upload`,
    method: "POST",
    addBearer: true,
    body: JSON.stringify({
      content_type: file.type,
      image_type: type
    })
  });

  return await fetch(result.put_url, {
    method: "PUT",
    body: file
  }).then(e => {
    if (e.ok) {
      console.log("image", result.get_url);
      return result.get_url;
    }
    throw e;
  });
};

export const getStats = async (id, start, end) =>
  await fetchWrapperPlatform({
    url: `users/${id}/stats/${start ? `?start=${start}` : ""}${end ? `&end=${end}` : ""}`,
    method: "GET",
    addBearer: true
  });

export const refreshUser = async () => {
  const user = await fetchWrapperPlatform({
    url: `users/me`,
    method: `GET`,
    addBearer: true
  });
  persistUser(user);
  return user;
};

export function persistUser(user) {
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
    setGlobal(user);
  } else {
    resetGlobal();
    localStorage.clear();
  }
}

export const getBanners = async userId =>
  await fetchWrapperPlatform({
    url: `users/${userId}/banners/`,
    method: `GET`,
    addBearer: true
  });

export const changePassword = async payload =>
  await fetchWrapperPlatform({
    url: "users/me/password/",
    method: "PUT",
    addBearer: true,
    body: JSON.stringify(payload)
  });

export const makeMod = async (broadcaster, moderator) =>
  await fetchWrapperPlatform({
    url: `users/${broadcaster}/moderators/${moderator}`,
    method: "PUT",
    addBearer: true,
    body: JSON.stringify({ broadcaster, moderator })
  });

export const removeMod = async (broadcaster, moderator) =>
  await fetchWrapperPlatform({
    url: `users/${broadcaster}/moderators/${moderator}`,
    method: "DELETE",
    addBearer: true,
    body: JSON.stringify({})
  });
