import React, { useContext, createContext, useState } from "reactn";
import GlobalLoader from "components/GlobalLoader";
import { systemAPI } from "utils/portal-apis";

const LoadingContext = createContext({});

export { GlobalLoader };

export const useGlobalLoader = () => {
  return useContext(LoadingContext);
};

export const LoaderStore = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>;
};

export const ThemeContext = createContext();
export const useGlobalTheme = () => {
  return useContext(ThemeContext);
};

export const checkVersion = async version => {
  console.log("Checking frontend version from database...");
  const dbVersion = await systemAPI.getFrontendVersion();

  if (!dbVersion) {
    console.log("Database ERROR. couldn't get frontend version information for cache control");
  } else {
    console.log("version from database is:", dbVersion);

    if (dbVersion !== version) {
      alert("There is an updated version of HotMic update available, this page will reload.");
      window.location.reload(true);
    } else {
      console.log("The frontend version is up-to-date.");
    }
  }
};
