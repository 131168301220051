// const portal_endpoint = "https://portal-api.hotmic.io";
// const portal_endpoint = "https://portal-test.api.hotmic.io"; //TODO change this back to Prod before deploy
const portal_endpoint = "https://portal-dev.api.hotmic.io"; //TODO change back to prod before deploy

export const fetchWrapperPortal = async ({ ...args }) => {
  const fetchObj = {};
  fetchObj.headers = args.headers || {};
  fetchObj.method = args.method.toUpperCase();
  fetchObj.body = args.body ? args.body : null;
  fetchObj.headers["Content-Type"] = "application/json";
  fetchObj.headers["ApiKey"] = process.env.REACT_APP_APP_KEY;
  if (args.addBearer) {
    fetchObj.headers["Authorization"] = args.token ? args.token : `Bearer ${localStorage.getItem("access_token")}`;
  }
  if (args.signal) {
    fetchObj.signal = args.signal;
  }
  try {
    const result = await fetch(`${portal_endpoint}/${args.url}`, fetchObj);
    return result && result.status === 204 ? result.status : await result.json();
  } catch (e) {
    console.log(`HTTP ERROR: ${e}`, args);
  }
};
