import { setGlobal, resetGlobal } from "reactn";
import { fetchWrapperPlatform } from "./fetch";
import { userAPI } from "utils/platform-apis";

function isGuestUser() {
  return localStorage.getItem("is_guest_user") === "true" || localStorage.getItem("is_guest_user") === true;
}

function onLoginResult(user, isGuestUser = false) {
  console.log("onLoginResult", user);
  if (user && user._id) {
    localStorage.setItem("access_token", user.tokens.access_token);
    localStorage.setItem("access_token_expires_at", user.tokens.access_token_expires_at);
    localStorage.setItem("refresh_token", user.refresh_token);
    localStorage.setItem("refresh_token_expires_at", user.tokens.refresh_token_expires_at);
    localStorage.setItem("is_guest_user", isGuestUser);
    console.log("localStorage", user);
  }
  userAPI.persistUser(user);
  return user ? user : false;
}

export const isLoggedIn = () => {
  if (isGuestUser()) {
    return false;
  }
  const exp = localStorage.getItem("access_token_expires_at");
  if (exp && exp >= new Date().getTime() / 1000) {
    const userObj = JSON.parse(localStorage.getItem("user"));
    setGlobal(userObj);
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["identify", { email: userObj.email }]);
    // eslint-disable-next-line no-unused-expressions
    window.HubSpotConversations?.widget?.refresh();
    return true;
  }
  console.log("User not logged in");
  return false;
};

export const isRefreshable = () => {
  if (isGuestUser()) {
    return false;
  }
  const exp = localStorage.getItem("refresh_token_expires_at");
  return exp && exp >= new Date().getTime() / 1000;
};

export const signup = async inputs => {
  const user = await userAPI.signup(inputs);
  console.log("signup", user);
  return onLoginResult(user);
};

export const login = async inputs => {
  const result = await fetchWrapperPlatform({
    url: `auth/login/`,
    method: `POST`,
    body: JSON.stringify(inputs)
  });
  console.log("login", result);
  return onLoginResult(result);
};

export const logout = async () => {
  resetGlobal();
  return localStorage.clear();
};

export const refreshAuth = async () => {
  const refresh_token = localStorage.getItem("refresh_token");
  const params = await fetchWrapperPlatform({
    url: `auth/refresh/`,
    method: `POST`,
    addBearer: true,
    token: refresh_token
  });
  return onLoginResult(params);
};

export const getResetToken = async token =>
  await fetchWrapperPlatform({
    url: `password/reset/${token}`,
    method: `GET`,
    addBearer: false
  });
