import { fetchWrapperPlatform } from "./fetch";

export const createSession = async (id, data) =>
  await fetchWrapperPlatform({
    url: `streams/${id}/sessions/`,
    method: `POST`,
    body: JSON.stringify(data)
  });

export const getSessionDuration = async (streamId, userId) =>
  await fetchWrapperPlatform({
    url: `streams/${streamId}/session/${userId}`,
    method: `GET`,
    addBearer: true
  });

export const getAll = async () =>
  await fetchWrapperPlatform({
    url: `streams/`,
    method: `GET`,
    addBearer: true
  });

export const getStreamInfo = async id =>
  await fetchWrapperPlatform({
    url: `streams/${id}/info/`,
    method: `GET`
  });

export const getChatByStreamId = async id =>
  await fetchWrapperPlatform({
    url: `streams/${id}/chats/`,
    method: `GET`,
    addBearer: true
  });

export const getTipsByStreamId = async id =>
  await fetchWrapperPlatform({
    url: `streams/${id}/tips/`,
    method: `GET`,
    addBearer: true
  });

export const getFeed = async streamId =>
  await fetchWrapperPlatform({
    url: `streams/${streamId}/feed/`,
    method: `GET`,
    addBearer: true
  });

export const getPolls = async streamId =>
  await fetchWrapperPlatform({
    url: `streams/${streamId}/polls/`,
    method: `GET`,
    addBearer: true
  });

export const getLeaderboard = async streamId =>
  await fetchWrapperPlatform({
    url: `streams/${streamId}/leaderboard/`,
    method: `GET`,
    addBearer: true
  });

export const requestCameo = async (streamId, sku) =>
  await fetchWrapperPlatform({
    url: `streams/${streamId}/guest_requests/me`,
    method: `POST`,
    addBearer: true,
    body: JSON.stringify({
      sku: sku
    })
  });

export const cancelCameoRequest = async (streamId, userId) =>
  await fetchWrapperPlatform({
    url: `streams/${streamId}/guest_requests/${userId}`,
    method: `DELETE`,
    addBearer: true
  });

export const joinCameo = async (streamId, request) =>
  await fetchWrapperPlatform({
    url: `streams/${streamId}/join/me`,
    method: `POST`,
    addBearer: true,
    body: JSON.stringify(request)
  });

export const getJoinProducts = async streamId =>
  await fetchWrapperPlatform({
    url: `streams/${streamId}/join/skus/`,
    method: "GET",
    addBearer: true
  });

export const getPublishToken = async streamId =>
  await fetchWrapperPlatform({
    url: `streams/${streamId}/publish_token/`,
    method: "POST",
    addBearer: true,
    body: JSON.stringify({})
  });

export const getWaitingToken = async streamId =>
  await fetchWrapperPlatform({
    url: `streams/${streamId}/publish_token/`,
    method: "POST",
    addBearer: true,
    body: JSON.stringify({ waiting: true })
  });

export const followHost = async hostId =>
  await fetchWrapperPlatform({
    url: `/users/${hostId}/followers/`,
    method: `POST`,
    addBearer: true
  });

export const unFollowHost = async hostId =>
  await fetchWrapperPlatform({
    url: `/users/${hostId}/followers/`,
    method: `DELETE`,
    addBearer: true
  });
