import { fetchWrapperPlatform } from "./fetch";
import { fetchWrapperPortal } from "../portal-apis/fetch";

export const addResponse = async (pollId, option) =>
  await fetchWrapperPlatform({
    url: `polls/${pollId}/responses`,
    method: `POST`,
    addBearer: true,
    body: JSON.stringify({ option })
  });

export const getLeaderboard = async pollId =>
  await fetchWrapperPlatform({
    url: `polls/leaderboard/${pollId}`,
    method: `GET`,
    addBearer: true
  });

export const getByStreamId = async streamId =>
  await fetchWrapperPortal({
    url: `polls/stream/${streamId}`,
    method: `GET`,
    addBearer: true
  });
