import { fetchWrapperPortal } from "./fetch";

// Helper function to determine the endpoint based on the type
const getEndpointByType = (type) => {
  return ["image", "overlay", "background"].includes(type) ? "images" : "videos";
};

export const getAll = async (currentPagination = 1, paginationSize = 50, query = null, type = "image", tag_filter = null, general_filter = null ) => {
  const skip = (currentPagination - 1) * paginationSize;
  const endpoint = getEndpointByType(type);
  const ending = type !== "video" ? `&type=${type}` : "";
  let url = `${endpoint}?skip=${skip}&limit=${paginationSize}${ending}`;
  if (query) {
    url += `&search=${query}`;
  }
  if (tag_filter) {
    url += `&tag_filter=${tag_filter}`;
  }
  if (general_filter) {
    url += `&general_filter=${general_filter}`;
  }
  const result = await fetchWrapperPortal({
    url: url,
    method: `GET`,
    addBearer: true,
  });
  //Little hack to add the type in the response (cause there's no type in teh videos collection)
  if (type === "video" || type === "image") {
    result.data = result.data.map(entry => ({ ...entry, type: type }));
  }
  console.log("Getting Media", result);
  return result;
};

export const getById = async (id, type = "image") => {
  const endpoint = getEndpointByType(type);
  return await fetchWrapperPortal({
    url: `${endpoint}/${id}`,
    method: `GET`,
    addBearer: true,
  });
};

export const search = async (query, type = "image") => {
  const endpoint = getEndpointByType(type);
  const ending = type !== "video" ? `&type=${type}` : "";
  return await fetchWrapperPortal({
    url: `${endpoint}/?search=${query}${ending}`,
    method: `GET`,
    addBearer: true,
  });
};

export const add = async (media) => {
  const endpoint = getEndpointByType(media.type);
  return await fetchWrapperPortal({
    url: `${endpoint}/`,
    method: `POST`,
    body: JSON.stringify(media),
    addBearer: true,
  });
};

export const update = async (mediaId, payload) => {
  const endpoint = getEndpointByType(payload.type);
  return await fetchWrapperPortal({
    url: `${endpoint}/` + mediaId,
    method: `PUT`,
    body: JSON.stringify(payload),
    addBearer: true,
  });
};

export const del = async (mediaId, type = "image") => {
  const endpoint = getEndpointByType(type);
  return await fetchWrapperPortal({
    url: `${endpoint}/` + mediaId,
    method: `DELETE`,
    addBearer: true,
  });
};

export const uploadImage = async (file, type = "image") => {
  console.log("upload", file.type, type);
  const result = await fetchWrapperPortal({
    url: `users/image_upload`,
    method: "POST",
    addBearer: true,
    body: JSON.stringify({
      content_type: file.type,
      image_type: type,
    }),
  });

  return await fetch(result.put_url, {
    method: "PUT",
    body: file,
  }).then((e) => {
    if (e.ok) {
      console.log("image", result.get_url);
      return result.get_url;
    }
    throw e;
  });
};
