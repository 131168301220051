import styled from "styled-components";

export default styled.div`
  height: 100%;
  width: 100%;
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${props => (props.$background ? props.$background : "transparent")};
`;
